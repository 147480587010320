import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import BookADemoHero from '../components/BookADemoHero'
import SeoHeader from '../components/SeoHeader'

export default function touch({ data }) {
  return (
    <React.Fragment>
      <SeoHeader
        title='Get In Touch'
      />
      <div className="mx-auto md:w-11/12">
        <div className="text-justify mb-8 md:w-2/3 md:mx-auto md:float-left">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.3483181460983!2d55.37639115105743!3d25.32609028375707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5b950e3e19b1%3A0x814b35cb8f9269d!2sCrescent%20Enterprises!5e0!3m2!1sen!2sae!4v1574575380235!5m2!1sen!2sae"
            width="100%"
            height="450"
            frameBorder="0"
            allowFullScreen
            title="mapView"
          />
        </div>
        <div className="px-8 text-justify mb-8 md:w-1/3 md:float-right">
          <h2>You can drop us an email anytime at:</h2>
          <a href="mailto:info@shamalworkwear.com" className="font-medium">
            info@shamalworkwear.com
          </a>
          <br className="mb-4" />
          <p>Or prefer to call:</p>
          <a href="tel:+971 6 554 7222" className="font-medium">
            tel:+971 6 554 7222
          </a>
          <br className="mb-4" />
          <p>Or visit us:</p>
          <a
            href="https://goo.gl/maps/HnNCSGxprWUQ3crUA"
            className="font-medium"
          >
            Crescent House, <br />
            Corniche Al Buhaira, <br />
            Sharjah, <br />
            United Arab Emirates <br />
          </a>
        </div>
      </div>
      <div className="breakup clearfix" />
      <BookADemoHero image={data.bookADemo} />
    </React.Fragment>
  )
}

touch.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    bookADemo: file(relativePath: { eq: "img/_AU_0569.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
